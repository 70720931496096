.twoCol__cntnt {
    width: 100%;
    max-width: 455px;
}

.twoCol__cntnt h1 {
    margin-bottom: 20px;
}

.twoCol__cntnt p {
    margin-bottom: 30px;
}

.twoCol:not(.imgRight) .twoCol__cntnt {
    margin: 0 auto;
}

.twoCol__img img {
    filter: drop-shadow(0 0px 15px rgba(0, 0, 0, 0.09));
}

@media only screen and (max-width:767px) {
    .twoCol__cntnt {
        padding-top: 15px;
        max-width: 100%;
    }

    .twoCol__cntnt p {
        margin-bottom: 15px;
    }

    .twoCol__cntnt .h1 br {
        display: none;
    }
}

@media only screen and (max-width:575px) {}

@media only screen and (max-width:480px) {}