.contact_address_list {
    display: flex;
    margin-bottom: 30px;
}

.contact_address_list .contact_address_icon {
    flex: 0 0 50px;
    margin-top: 5px;
}

.contact_address_list .contact_address_icon img {
    width: 30px;
    margin: 0;
}

.successMessage {
    color: #0b844b;

    letter-spacing: 0.02em;
    line-height: 1.3;
}

.alertBox {
    padding: 10px 15px;
}