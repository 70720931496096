@import url(../Assets/Fonts/stylesheet.css);

:root {
  --primary: #f1592a;
  --primaryhover: #d53f10;
  --black: #000;
  --darkblue: #060d29;
  --white: #fff;
  --whitetint1: #f8f8f8;
  --whitetint2: #f9f9f9;
  --grey: #827f7f;
  --greytint2: #6f6d6d;
  --bodyFont: 'Aktiv Grotesk';
}


html, body, #root, #root>div {
  min-height: 100vh;
}


body {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  font-family: var(--bodyFont), "Helvetica Neue", "Helvetica", Helvetica, Arial;
  color: var(--greytint2);
  font-size: 16px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  color: var(--white);
  background-color: var(--primary);
}

::selection {
  color: var(--white);
  background-color: var(--primary);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b1b1b1 #f5f5f5;
}

*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 20px;
  border: 1px solid #f5f5f5;
}


a {
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

button {
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}


/* a:hover {
  color: var(--primaryHover);
} */

img {
  max-width: 100%;
  height: auto;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: 700;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

a:hover, a:focus, button, button:hover, button:focus, input:focus, textarea:focus, select:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

details {
  display: block;
}

summary {
  display: list-item;
}


[hidden] {
  display: none !important;
}


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
}

button {
  cursor: pointer;
}

code,
pre {
  font-family: "SFMono-Regular", "SF Mono", "Ubuntu Mono", "Consolas", "DejaVu Sans Mono", "Menlo", monospace;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-top: 0;
  color: var(--black);
}

h1, .h1 {
  font-size: 46px;
  line-height: 55px;
}

h2, .h2 {
  font-size: 40px;
}

h3, .h3 {
  font-size: 36px;
}

h4, .h4 {
  font-size: 30px;
}

h5, .h5 {
  font-size: 24px;
}

h6, .h6 {
  font-size: 18px;
}

p:last-child {
  margin: 0;
}


.btn {
  padding: 15px 30px;
}

.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
  background: var(--primaryhover);
  border-color: var(--primaryhover);
  color: var(--white);
}

.form-control {
  height: 50px;
  border-color: var(--black);
  color: var(--black);
  font-size: 16px;
}
.form-control.error {
  border-color: red;
  background-color: rgba(255,0,0,0.05);
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--black);
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--greytint2);
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--greytint2);
  font-weight: 400;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--greytint2);
  font-weight: 400;
}


.greyBg {
  background: var(--whitetint2);
}

.textBlack {
  color: var(--black);
}

.primaryClr {
  color: var(--primary);
}

.fontRegular {
  font-weight: 400;
}

.fontMedium {
  font-weight: 500;
}

.fontSemibold {
  font-weight: 600;
}

.fontBold {
  font-weight: 700;
}

.commonPadding {
  padding: 80px 0;
}

.commonHeading {
  margin-bottom: 70px;
}

section.twocolSect:nth-last-of-type(2n+1) {
  background: var(--whitetint2);
}

.App {
  display: flex;
  flex-direction: column;
}

.verifyPage {
  flex: 1 0 auto;
}

.verifyPage {
  /* min-height: 100vh; */
  padding: 40px 0;
  /* background: rgb(249, 249, 249); */
  /* background: linear-gradient(0deg, rgba(249, 249, 249, 1) 50%, rgba(255, 255, 255, 1) 50%); */
  background-color: #fff;
}

.verifyPage__container {
  max-width: 500px;
  width: calc(100% - 30px);
}

.verifyPage__content {
  padding: 15px 0 10px;
}

.text-greytint2 {
  color: var(--greytint2);
}
.form-select-outer{
  position: relative;
}
.form-select-outer:after {
  content: "";
  background-image: url(../Assets/Images/bx-chevron-down.svg);
  background-size: 25px;
  background-position: calc(100% - 7px) 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 99;
  transform: translateY(-50%);
  pointer-events: none;
}

select.form-control {
  padding-right: 37px;
}
.testimonialCard__cntnt.h5 {
  font-size: 21px;
  letter-spacing: 0.5px;
  color: var(--black);
}
.termsPageContent h5,
.termsPageContent h6{
  margin-bottom: 0.5rem;
}

@media only screen and (max-width:1199px) {
  .commonPadding {
    padding: 60px 0;
  }

  h1, .h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2, .h2 {
    font-size: 36px;
  }

  h3, .h3 {
    font-size: 30px;
  }

  h4, .h4 {
    font-size: 26px;
  }

  h5, .h5 {
    font-size: 23px;
  }

  h6, .h6 {
    font-size: 17px;
  }

  .commonHeading {
    margin-bottom: 60px;
  }

}

@media only screen and (max-width:991px) {
  .commonPadding {
    padding: 40px 0;
  }

  .commonHeading {
    margin-bottom: 40px;
  }

  h1, .h1 {
    font-size: 36px;
    line-height: 44px;
  }

  h2, .h2 {
    font-size: 30px;
  }

  h3, .h3 {
    font-size: 26px;
  }

  h4, .h4 {
    font-size: 24px;
  }

  h5, .h5 {
    font-size: 20px;
  }

  h6, .h6 {
    font-size: 17px;
  }

}

@media only screen and (max-width:767px) {
  .commonPadding {
    padding: 30px 0;
  }

  .commonHeading {
    margin-bottom: 30px;
  }

  h1, .h1 {
    font-size: 32px;
    line-height: 44px;
  }

  h2, .h2 {
    font-size: 26px;
  }

  h3, .h3 {
    font-size: 24px;
  }

  h4, .h4 {
    font-size: 22px;
  }

  h5, .h5 {
    font-size: 19px;
  }

  h6, .h6 {
    font-size: 16px;
  }

  .btn {
    padding: 8px 25px;
    font-size: 14px;
  }

  .commonHeading br, .commonHeading.text-center .h1 br {
    display: none;
  }
}

@media only screen and (max-width:575px) {
  .testimonialCard__cntnt.h5 {
    font-size: 16px;
    letter-spacing: 0;
}
  h1, .h1 {
    font-size: 28px;
    line-height: 34px;
  }

  h2, .h2 {
    font-size: 25px;
  }

  h3, .h3 {
    font-size: 22px;
  }

  h4, .h4 {
    font-size: 21px;
  }

  h5, .h5 {
    font-size: 18px;
  }

  h6, .h6 {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 1.2rem;
  }

  .homeBanner .bannerAbsImg {
    right: -10px;
    width: 196px;
  }

}

@media only screen and (max-width:480px) {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 1rem;
  }
}




@media (prefers-color-scheme: dark) {
  :root {
    --white: #000;
    --black: #fff;
    --darkblue: #c6d2ff;
    --whitetint1: #292929;
    --whitetint2: #242424;
  }

}