.IconBoxRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 80px;
    row-gap: 80px;
}

.iconBox__img {
    height: 70px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.iconBox img {
    max-width: 70px;
    max-height: 70px;
    width: auto;
    height: auto;
    margin: 0;
    display: block;
}

.iconBox .h5 {
    margin-bottom: 10px;
}

.iconBox p {
    width: 100%;
    max-width: 320px;
}

@media only screen and (max-width:991px) {
    .IconBoxRow {
        column-gap: 30px;
        row-gap: 30px;
    }
}

@media only screen and (max-width:767px) {
    .IconBoxRow {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width:480px) {
    .iconBox {
        text-align: center;
    }
    .iconBox img { 
        margin: 0 auto;
    }
    .IconBoxRow {
        grid-template-columns: repeat(1, 1fr);
    }

    .iconBox p {
        max-width: 100%;
    }
}