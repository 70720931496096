.footermain {
    background: var(--black);
}

.footermain__Top {
    padding: 25px 0;
    /* padding: 95px 0 65px; */
}

.footermain__bottom {
    padding: 15px 0;
    border-top: 1px solid #262626;
}
.footerMenuBox ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.footerMenuBox ul li+li {
    margin-top: 5px;
}

.footerMenuBox ul li a {
    color: var(--white);
    display: inline-block;
    padding: 2px 0;
    transition: 0.2s ease-in-out;
}

.footerMenuBox ul li a:hover {
    color: var(--primary);
}

.footerMenuBox__title {
    color: var(--white);
}

.footermain__bottomLinks {
    /* padding-left: 22px; */
}

.footermain__bottomLinks ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footermain__bottomLinks ul {}

.footermain__bottomLinks ul li+li {
    padding-left: 20px;
    position: relative;
}

.footermain__bottomLinks ul li+li::before {
    content: '';
    width: 1px;
    background: var(--white);
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
}

.footermain__bottomLinks ul li a {
    color: var(--white);
    transition: 0.2s ease-in-out;
    font-size: 14px;
}

.footermain__bottomLinks ul li a:hover {
    color: var(--primary);
}

.copyright {
    color: var(--white);
    font-size: 14px;
}
.copyright a{
    color: var(--white);
}
.copyright a:hover{
    color: var(--primary);
}

.subscribeBox .footerMenuBox__title {
    color: var(--white);
}

.subscribeBox__grp {
    position: relative;
}

.subscribeBox__grp button {
    border: 0;
    background: transparent;
    position: absolute;
    right: 16px;
    top: 15px;
}

.subscribeBox__grp button img {
    width: 24px;
}

.subscribeBox__grp .form-control {
    padding-right: 50px;
}

.footer__social {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-top: 30px;
}

.footer__social li+li {
    margin-left: 20px;
}

.footer__social li a {
    display: block;
    transition: 0.3s ease-in-out;
}

.footer__social li a:hover {
    transform: scale(1.2) rotate(360deg);
    transform-origin: center center;
}

.footer__social li a img {
    filter: invert(1);
    width: 25px;
    transition: inherit;
}

/* New */
.footerMenuBox ul li {
    display: inline-block;
    padding: 0 20px;
    font-size: 18px;
}
.footermain__bottomLinks ul li {
    line-height: 1;
}


@media only screen and (max-width: 1199px) {
    .footermain__bottomCntnt {
        font-size: 14px;
    }
}

@media only screen and (max-width: 991px) {
    .footermain__Top {
        padding: 60px 0;
    }
}

@media only screen and (max-width: 767px) {
    .footermain__bottomCntnt {
        flex-direction: column;
        align-items: center;
    }

    .footermain__bottomLinks {
        margin: 14px 0px 0;
    }

    .footermain__bottomLinks {
        padding-left: 0;
    }

    .footermain__Top {
        padding: 40px 0;
    }

    .footerMenuBox ul li {
        padding: 0 15px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 575px) {
    .footermain__bottomLinks {
        flex-direction: column;
    }

    .copyright {
        margin-top: 10px;
    }

    .footermain__Top {
        padding: 30px 0;
    }

    .footerMenuBox {
        margin-top: 20px;
    }

    .footerMenuBox__title {
        margin-bottom: 5px;
    }

    .footer__social {
        margin: 20px 0 0 0;
    }
}