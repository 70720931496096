.pricingBox {
    background: var(--white);
    border: 1px solid transparent;
    border-radius: 10px;
    /* overflow: hidden; */
    box-shadow: 0px 52px 27px -41px rgba(0, 0, 0, 0.23), 0px 0 27px 0 rgba(0, 0, 0, 0.05);
    transition: 0.4s ease-in-out;

}

.pricingBox:hover {
    box-shadow: 0px 52px 27px -41px rgba(0, 0, 0, 0), 0px 0 27px 0 rgba(0, 0, 0, 0);
    border-color: #d3d3d3;
}

.pricingBox__header {
    text-align: center;
    /* margin: 0; */
    background: #00d9ae;
    padding: 25px 15px;
    position: relative;
    
    width: 85%;
    margin: -25px auto 0;
    border-radius: 10px;
    color: var(--white);
    overflow: hidden;
}
.offer_badge {
    background: #9f3717;
    position: absolute;
    top: 16px;
    left: -33px;
    font-size: 14px;
    width: auto;
    transform: rotate(-45deg) scale(1);
    padding: 3px 35px;
    /* border-bottom: #5e2020 1px solid; */
    box-shadow: inset rgba(255,255,255,0.5) 0 1px 2px, inset rgba(0,0,0,0.4) 0 -2px 2px, rgba(0,0,0,0.4) 0 2px 8px;
    font-weight: 600;
    /* animation: pulse 0.5s infinite linear backwards; */
}

@keyframes pulse {
    0%, 100%{
        transform: rotate(-45deg) scale(1);
    }
    50%{
        transform: rotate(-45deg) scale(1.1);
    }
}

.pricingBox__header *{
    color: var(--white) !important;
}
.pricingBox__header .h2{
    line-height: 1.4;
}
.pricingBox__header del{
    font-weight: 400;
    opacity: 0.9;
}

.pricingBox__header .popular {
    background: var(--black);
    border-radius: 0 10px 0 10px;
    font-size: 12px;
    padding: 5px 15px;
    position: absolute;
    right: 0;
    top: 0;
}

.pricingBox__header.h6 {
    margin: 0;
}

.pricingGrid {
    padding: 0 50px;
    display: grid;
    grid-template-columns: repeat(2, 33.33%);
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.pricingBox__body {
    padding: 30px 65px;
}

/* .pricingBox:nth-child(2) .pricingBox__body {
    padding-top: 90px;
    padding-bottom: 90px;
} */

.pricingBox:nth-child(2) .pricingBox__header {
    background: #f1592a;
    color: var(--white);
}

/* .pricingBox:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
}

.pricingBox:nth-child(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
} */

.pricingBox__price sub {
    bottom: -.02em;
    font-size: 18px;
}

.pricingBox__body p {
    margin-bottom: 25px;
}

.pricingBox__body ul {
    margin: 0 0 35px 0;
    padding-left: 0;
    list-style: none;
}

.pricingBox__body ul li+li {
    margin-top: 7px;
}

.pricingBox__body ul li {
    font-size: 14px;
    padding-left: 20px;
    color: var(--black);
    position: relative;
}

.pricingBox__body ul li::before {
    content: '-';
    /* width: 8px; */
    /* height: 8px; */
    border-radius: 50%;
    /* background: #c9c9c9; */
    position: absolute;
    left: 0;
    /* top: 7px; */
}

/*  */
.accordion-item+.accordion-item {
    margin-top: 18px;
}

.accordion .accordion-item {
    border: 1px solid var(--black) !important;
    border-radius: 5px;
    overflow: hidden;
}

.accordion .accordion-button {
    color: var(--black);
    font-weight: 700;
    font-size: 24px;
    outline: 0 !important;
    padding: 25px;
    background-color: var(--white);
}

.accordion .accordion-body {
    padding: 0 25px 25px;
    font-size: 16px;
    line-height: 22px;
    color: #8d8d8d;
}

.accordion .accordion-button::after {
    content: "";
    transform: rotate(0);
    width: 26px;
    height: 26px;
    background-image: url(../../../Assets/Images/plus.svg);
    background-position: center center;
    background-size: 17px;
}

.accordion .accordion-button:not(.collapsed)::after {
    content: "";
    transform: rotate(0);
    background-image: url(../../../Assets/Images/minus.svg);
}

.accordion .accordion-header {
    outline: 0 !important;
}

.accordion .accordion-button:not(.collapsed) {
    color: var(--black);
    background: transparent;
    border: 0;
    box-shadow: none;
}

.loadingText span {
    width: 130px;
    display: inline-block;
}

.accordion {
    margin-bottom: 45px;
}

.loadingText {
    font-size: 24px;
    font-weight: 700;
    color: #e1e1e1;
}


/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #e1e1e1;
    color: #e1e1e1;
    box-shadow: 9999px 0 0 -5px #e1e1e1;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #e1e1e1;
    color: #e1e1e1;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #e1e1e1;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #e1e1e1;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #e1e1e1;
    }

    30% {
        box-shadow: 9984px 0 0 2px #e1e1e1;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #e1e1e1;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #e1e1e1;
    }

    30% {
        box-shadow: 9999px 0 0 2px #e1e1e1;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #e1e1e1;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #e1e1e1;
    }

    30% {
        box-shadow: 10014px 0 0 2px #e1e1e1;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #e1e1e1;
    }
}



@media only screen and (max-width:1300px) {
    .pricingBox__body {
        padding: 30px 35px;
    }

    /* .pricingBox:nth-child(2) .pricingBox__body {
        padding-top: 60px;
        padding-bottom: 60px;
    } */
    .pricingBox__body ul li+li {
        margin-top: 5px;
    }

    .pricingBox__body ul {
        margin-top: 29px;
    }
}

@media only screen and (max-width:1199px) {
    .pricingBox__body {
        padding: 20px 25px;
    }

    /* .pricingBox:nth-child(2) .pricingBox__body {
        padding-top: 50px;
        padding-bottom: 50px; 
    } */
    .pricingGrid {
        grid-template-columns: repeat(2, 43.33%);
    }

}

@media only screen and (max-width:991px) {
    .pricingGrid {
        padding: 0 0;
    }

    .pricingBox__body {
        padding: 20px 15px;
    }

    .accordion .accordion-button {
        font-size: 20px;
        padding: 15px 25px;
    }

    .pricingGrid {
        grid-template-columns: repeat(2, 48%);
    }
}

@media only screen and (max-width:767px) {
    .pricingGrid {
        grid-template-columns: repeat(1, 1fr);
    }

    .pricingBox:nth-child(1) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-right-width: 1px;
    }

    .pricingBox:nth-child(3) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left-width: 1px;
    }

    .pricingBox+.pricingBox {
        margin-top: 20px;
    }

    .accordion .accordion-button {
        font-size: 18px;
        padding: 15px 15px;
    }
}

@media only screen and (max-width:575px) {
    .accordion .accordion-button {
        font-size: 16px;
    }

    .accordion .accordion-button::after {
        width: 20px;
        height: 20px;
        background-size: 12px;
    }
}


@media (prefers-color-scheme: dark) {
    .accordion .accordion-button::after {
        filter: invert();
    }
}