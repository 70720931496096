.banner {
    overflow: hidden;
    overflow-y: unset;
}

.banner__cntntInnr {
    width: 100%;
    max-width: 420px;
}

.bannerImg {
    position: relative;
}

.bannerAbsImg {
    position: absolute;
}


.bannerForm .form-control {
    flex: 0 0 270px;
    max-width: 270px;
    height: 45px;
    box-shadow: 0 0 117px 0 rgba(0, 0, 0, 0.09);
}

.bannerForm .btn-primary {
    padding: 9px 30px;
    box-shadow: 0 0 117px 0 rgba(0, 0, 0, 0.09);
}

.bannerForm {
    column-gap: 10px;
}


@media only screen and (max-width:1399px) {
    .bannerForm .btn-primary {
        padding: 9px 10px;
    }
}

@media only screen and (max-width:767px) {
    .banner .row {
        flex-direction: column-reverse;
    }

    .bannerImg {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width:575px) {
    .bannerForm .form-control {
        height: 41px;
    }
}