.testimonialCard {
    padding: 60px 60px;
    border-radius: 15px;
    background: var(--whitetint2);
    box-shadow: 0 0 201px 0 rgba(0, 0, 0, 0.09);
}

.testimonialCard_avatar {
    width: 69px;
    height: 69px;
    flex: 0 0 69px;
    border-radius: 50%;
    overflow: hidden;
}

.testimonialCard_avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonialCard__name {
    padding-left: 15px;
}

.testimonialCard__top {
    margin-bottom: 30px;
}

.testimonialCard__right img {
    width: 35px;
}

@media only screen and (max-width:1199px) {
    .testimonialCard {
        padding: 45px;
    }
}

@media only screen and (max-width:991px) {
    .testimonialCard {
        padding: 30px 30px;
    }
}

@media only screen and (max-width:767px) {
    .testimonialCard {
        padding: 20px;
    }
}

@media only screen and (max-width:575px) {
    body {
        font-size: 14px;
        line-height: 20px;
    }
}