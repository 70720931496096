.socialHandle {
    list-style: none;
    margin: 0;
}

.socialHandle li a {
    display: block;
    transition: 0.2s ease-in-out;
    opacity: 0.5;
}

.socialHandle li a:hover {
    opacity: 1;
}

.socialHandle li img {
    width: 24px;
    display: block;
}

.socialHandle li+li {
    padding-left: 15px;
}

.shareDetailbox {
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 25px;
}

.blogDetailContent img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 25px;
}

.blogDetailContent ul,
.blogDetailContent ol {
    padding-left: 17px;
    margin-bottom: 25px;
}


.blogDetailContent ul li+li,
.blogDetailContent ol li+li {
    margin-top: 10px;
}

.authorDetailBox {
    padding: 47px 64px;
    margin-top: 50px;
    border-radius: 5px;
}

.authorDetailBox__img {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 135px;
}

.authorDetailBox__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.authorDetailBox__detail {
    padding-left: 54px;
    flex: 0 0 calc(100% - 135px);
}

.authorDetailBox__detail h4 {
    margin-bottom: 12px;
}

.blogDetailContent a {
    cursor: pointer;
}

.blogDetailContent a {
    color: var(--primary);
}

.blogDetailContent a:hover {
    color: var(--primaryhover);
    text-decoration: underline;
}

@media only screen and (max-width: 991px) {
    .authorDetailBox {
        padding: 20px 15px;
        margin-top: 50px;
        border-radius: 5px;
    }

    .authorDetailBox__img {
        width: 105px;
        height: 105px;
        border-radius: 50%;
        overflow: hidden;
        flex: 0 0 105px;
    }

    .authorDetailBox__detail {
        padding-left: 15px;
        flex-basis: calc(100% - 105px);
    }
}

@media only screen and (max-width: 767px) {
    .socialHandle li+li {
        padding-left: 10px;
    }
    .shareDetailbox {
        flex-direction: column;
    }
}