.headerHeight {
    height: 98px;
}

.navbar_outer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
}
.navbar.navbar-light {
    background: var(--white);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    padding: 20px 0;
}

.navbarMainRow {
    padding: 0 19px;
}

.navbarMain__ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navbarMain__ul li {
    list-style: none;
}

.headerMain__listBtn {
    color: var(--black);
    padding: 0 16px;
}

.headerMain__listBtn:hover, .headerMain__listBtn.active {
    color: var(--primary);
}

.submenu {
    margin: 0;
    padding: 15px 0;
}



.dropdown .caret::after {
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    border: .3em solid var(--black);
    border-bottom-width: 0;
    border-right-color: transparent;
    border-left-color: transparent;
}

.dropdown .headerMain__listBtn.active+.caret::after {
    border-top-color: var(--primary);
}

.dropdown .caret {
    position: absolute;
    right: 4px;
    border: 0;
    padding: 0;
    background: transparent;
}

.navbar-toggler .navbar-toggler-icon {
    background-image: none;
}



/*  */
/* .navbar-toggler.collapsed { */

.navbar .navbar-toggler {
    width: 25px;
    height: 40px;
    transition-duration: 0.5s;
    cursor: pointer;
    position: absolute;
    right: 15px;
    padding: 0;
    border: 0;
}

.navbar .navbar-toggler:focus {
    box-shadow: none;
}

.navbar .navbar-toggler span {
    transition-duration: 0.5s;
    position: absolute;
    width: 25px;
    height: 2px;
    top: 18px;
    background-color: var(--black);
    left: 0;
    right: 0;
    margin: auto;
}

.navbar .navbar-toggler span::before, .navbar .navbar-toggler span::after {
    content: "";
    transition-duration: 0.5s;
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: var(--black);
    left: 0;
}

.navbar .navbar-toggler span:before {
    top: -7px;
}

.navbar .navbar-toggler span:after {
    top: 7px;
}

.navbar .navbar-toggler:not(.collapsed) span {
    transition-duration: 0.5s;
    background: transparent;
}

.navbar .navbar-toggler:not(.collapsed) span::before {
    transform: rotateZ(45deg) scaleX(1.25) translate(5.5px, 4.5px);
}

.navbar .navbar-toggler:not(.collapsed) span::after {
    transform: rotateZ(-45deg) scaleX(1.25) translate(4px, -4px);
}


.announcement_bar{
    background-color: var(--primary);
    text-align: center;
    color: var(--white);
}
.announcement_bar p{
    font-size: 14px;
    padding: 5px 0;
    letter-spacing: 0.1px;
}
.announcement_bar a{
    color: #fff !important;
    font-size: 14px;
}

@media only screen and (min-width:992px) {
    .submenu {
        margin: 0;
        padding: 15px 0;
        position: absolute;
        left: 0;
        width: 200px;
        background: var(--white);
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
        opacity: 0;
        transform: translateY(20px);
        pointer-events: none;
        transition: 0.3s ease-in-out;
    }

    .dropdown:hover .submenu {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: all;
    }

    .submenu li {
        padding: 8px 10px;
    }
}

@media only screen and (max-width:991px) {

    .navbar-collapse {
        background: var(--white);
        padding: 10px 0;
        position: absolute;
        right: 0;
        left: 0;
        top: 100%;
        z-index: 21;
        box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.10);
    }

    .submenu {
        background: var(--whitetint2);
        padding: 0;
        height: 0;
        overflow: hidden;
        transition: 0.3s ease-in-out;
    }

    .navbarMain__ul .submenu li {
        padding: 5px 8px;
    }

    .dropdown.openmenu .submenu {
        height: auto;
    }

    .headerMain__listBtn {
        padding: 8px 16px;
        display: block;
    }

    .navbar .container {
        position: relative;
    }

    .navbarBtn {
        margin-right: 40px;
    }

    .navbarMain__ul li {
        padding: 0;
    }

    .navbarMain__ul {
        flex-direction: column;
    }

    .navbarMainRow {
        padding: 0;
        flex-direction: column;
    }

    .dropdown .caret {
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 1;
        width: 30px;
        height: 30px;
        background-image: url(../../Assets/Images/bx-plus.svg);
        background-size: 20px;
        background-position: center center;
        background-repeat: no-repeat;
    }
    
    .dropdown.openmenu .caret { 
        background-image: url(../../Assets/Images/bx-minus.svg);        
    }

    .dropdown .caret::after {
        border-width: 8px 8px 0 8px;
        display: none;
    }

    .navbar.navbar-light {
        padding: 15px 0;
    }

    .headerHeight {
        height: 88px;
    }
}

@media only screen and (max-width:575px) {
    .navbar-brand img {
        width: 110px;
    }

    .navbarBtn.btn.btn-primary {
        padding: 9px 14px;
        font-size: 14px;
    }

    .headerHeight {
        height: 77px;
    }
}

@media only screen and (max-width:360px) {
    .navbar-brand img {
        width: 90px;
    }
}
