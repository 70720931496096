.LearnBox__cntnt {
    padding: 20px 0 15px;
}

.LearnBox__detail {
    margin: 0 0 15px 0;
}

.LearnGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 58px;
    grid-row-gap: 50px;
}

.LearnBox__cntnt {
    padding: 20px 0 0;
}

.LearnBox__img {
    width: 100%;
    height: 236px;
    border-radius: 5px;
    overflow: hidden;
}

.LearnBox__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LearnBox__cntnt .h5 {
    margin: 0;
    transition: 0.2s ease-in-out;
}

.LearnBox:hover .LearnBox__cntnt .h5 {
    color: var(--primary);
}

.LearnBox__detail .date {
    margin: 0;
    color: #827f7f;
}

.learnRecomendBox {
    padding-top: 10px;
}

.learnRecomendBox ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.learnRecomendBox ul li {
    border-top: 1px solid #cccccc;
    padding: 20px 0;
}

.learnRecomendBox ul li:last-child {
    border-bottom: 1px solid #cccccc;
}

.learnRecomendBox ul li a {
    color: var(--black);
    transition: 0.2s ease-in-out;
}

.learnRecomendBox ul li a:hover {
    color: var(--primary);
}

@media only screen and (min-width:576px) {
    .LearnBox:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

@media only screen and (min-width:992px) {
    .LearnBox:first-child .LearnBox__cntnt .h5 {
        font-size: 30px;
    }
}

@media only screen and (min-width:768px) {
    .LearnBox:first-child .LearnBox__cntnt .h5 {
        font-size: 26px;
    }
    .LearnBox:first-child .LearnBox__img {
        height: 400px;
    }
    
}

@media only screen and (max-width:991px) {
    .LearnGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 30px;
    }
    .learnRecomendBox ul li { 
        padding: 10px 0;
    }

}

@media only screen and (max-width:767px) {
    .hideonMobile {
        display: none;
    }
    .LearnBox__detail {
        margin: 0 0 10px 0;
    }
}

@media only screen and (max-width:575px) {
    .LearnGrid {
        display: grid;
        grid-template-columns: 100%;
        grid-column-gap: 0;
        grid-row-gap: 20px;
    }

    .LearnBox__cntnt {
        padding: 10px 0 0;
    }

    .LearnBox__detail {
        margin-bottom: 8px;
    }
}