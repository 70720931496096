.homeBanner {
    background: var(--whitetint2);
}

.homeBanner .bannerAbsImg {
    width: 366px;
    right: -170px;
    bottom: -52px;
}

.homeSellBox {
    padding-left: 20px;
}

.homeSellBox__img {
    border: 1px solid var(--black);
    border-radius: 5px;
    position: relative;
    width: 288px;
    max-width: 100%;
    padding: 15px 15px;
    margin-bottom: 30px;
    box-shadow: 0 0 114px 0 rgba(0, 0, 0, 0.14);
}

.homeSellBox__absImg {
    position: absolute;
    width: 75px;
    height: 75px;
    border-radius: 5px;
    background: var(--white);
    top: 50%;
    right: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 114px 0 rgba(0, 0, 0, 0.14);
    transform: translateY(-50%);
}

.homeSellBox__absImg img {
    width: 48px;
}

.homeSellBox__cntnt {
    width: 100%;
    max-width: 300px;
}

.homeSellBox__cntnt h5 {
    margin-bottom: 20px;
}

.homeSellBox__cntnt p {}

.testimonialSlider .slick-slide {
    padding: 20px 17px;
}

.testimonialSlider .slick-slide:not(.slick-active) {
    opacity: 0.62;
}

/* the parent */
.testimonialSlider .slick-list {
    margin: 0 -17px;
}

.testimonialSlider .slick-list {
    overflow: visible;
}

.testimonialSection {
    overflow: hidden;
}

.testimonialSlider .slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 70px;
}

.testimonialSlider .slick-dots li {
    display: inline-block
}

.testimonialSlider .slick-dots li+li {
    margin-left: 33px;
}

.testimonialSlider .slick-dots li button {
    display: inline-block;
    width: 58px;
    height: 4px;
    font-size: 0px;
    overflow: hidden;
    border: 0;
    background: #a3a1a2;
}

.testimonialSlider .slick-dots li.slick-active button {
    background: #222222;
}

@media only screen and (max-width:1199px) {
    .bannerForm.d-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    .bannerForm .form-control {
        flex: 0 0 auto;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .homeBanner .bannerAbsImg {
        width: 246px;
        right: -30px;
        bottom: -32px;
    }

    .homeSellBox__absImg {
        width: 50px;
        height: 50px;
        right: -24px;
    }

    .homeSellBox__absImg img {
        width: 28px;
    }

}

@media only screen and (max-width:991px) {
    .testimonialSlider .slick-slide {
        padding: 20px 10px;
    }

    .testimonialSlider .slick-list {
        margin: 0 -10px;
    }
}

/* 991 */
@media only screen and (max-width:767px) {
    .homeSellBox {
        text-align: center;
        padding-left: 0;
    }

    .homeSellBox__img, .homeSellBox__cntnt {
        margin: 0 auto 30px;
    }

    .testimonialSlider .slick-dots li button {
        width: 38px;
    }

    .testimonialSlider .slick-dots {
        margin-top: 10px;
    }

    .testimonialSlider .slick-dots li+li {
        margin-left: 15px;
    }

    .homeSellBox__cntnt h5 {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:575px) {
    .homeSellBox__absImg {
        width: 40px;
        height: 39px;
        right: -14px;
    }
    .testimonialSlider .slick-dots li button {
        width: 20px;
    }
    .testimonialSlider .slick-dots li+li {
        margin-left: 10px;
    }
}
@media only screen and (max-width:480px) {
    .centerMobile {
        text-align: center;
    }
}
