.sellBanner {
    background: var(--whitetint1)
}

.sellBanner .bannerAbsImg {
    left: -80px;
    bottom: -50px;
    width: 212px;
}

.sellAroundWorld .container {
    position: relative;
}

.sellAroundWorld__Img {
    position: relative;
    left: -200px;
    width: 560px;
}

.sellAroundWorld__right {
    width: 100%;
    max-width: 503px;
    margin: 0 auto;
}

.sellAroundWorld__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 80px;
}

.sellAroundWorld__box {}

.sellAroundWorld__box .title {
    font-size: 72px;
    font-weight: 700;
    line-height: 76px;
    color: var(--black);
}

.sellAroundWorld__box h6 {
    font-weight: 700;
    margin: 0;
}

.sellAroundWorld__rightTitle {
    margin-bottom: 50px;
}

.startSellingBox {
    background: var(--primary);
    border-radius: 10px;
}

.startSellingBox__cntnt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0 10px;
}

.startSellingBox__cntnt .h1 {
    color: var(--white);
    margin-bottom: 25px;
}

.startSellingBox__cntnt .h6 {
    color: var(--white);
    margin-bottom: 10px;
}

/* insta */
.sellIntaBox__row {
    display: grid;
    grid-template-columns: repeat(3, 287px);
    justify-content: space-between;
    grid-column-gap: 15px;
    grid-row-gap: 15px;

}

.sellIntaBox__cntnt span {
    display: inline-block;
    margin-right: 15px;
    color: #e8e8e8;
}

.twocolSect--insta .twoCol__img {
    max-width: 346px;
    margin: 0 auto;
}

.twocolSect--insta .twoCol__cntnt {
    width: 100%;
    max-width: 482px;
}

.sellInstaBanner {
    background: var(--whitetint2);
}

.startSellingBox__img {
    width: 300px;
}

@media only screen and (max-width:1199px) {
    .sellBanner .bannerAbsImg {
        left: -20px;
        bottom: -10px;
        width: 142px;
    }

    .sellIntaBox__row {
        grid-template-columns: repeat(3, calc(33% - 15px));
    }

}

@media only screen and (max-width:991px) {
    .sellBanner .bannerAbsImg {
        left: -21px;
        bottom: -23px;
        width: 122px;
    }

    .sellAroundWorld__Img {
        width: 560px;
        max-width: 100%;
    }

    .sellAroundWorld__box .title {
        font-size: 52px;
        line-height: 59px;
    }

    .sellAroundWorld__grid {
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }

    .sellAroundWorld__Img {
        left: 0;
    }

    .startSellingBox__cntnt .h1 {
        margin-bottom: 15px;
    }

    .startSellingBox__cntnt .h6 {
        margin-bottom: 0;
    }

    .sellAroundWorld__box .title {
        font-size: 44px;
        line-height: 49px;
    }
}

@media only screen and (max-width:767px) {
    .startSellingBox__img {
        margin-right: auto;
        margin-bottom: 20px;
    }

    .startSellingBox__cntnt {
        align-items: center;
        text-align: center;
    }

    .startSellingBox__cntnt {
        padding-bottom: 15px;
    }

    .sellAroundWorld__Img {
        width: calc(100% - 100px);
        margin: 0 auto 30px;
    }

    .sellAroundWorld__grid {
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    }

    .startSellingBox {
        padding: 15px;
    }

    .sellIntaBox__row {
        grid-template-columns: repeat(1, 100%);
    }

    .sellIntaBox {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
    }
}

@media only screen and (max-width:575px) {
    .sellBanner .bannerAbsImg {
        left: -7px;
    }

    .sellAroundWorld__box .title {
        font-size: 35px;
        line-height: 38px;
    }
}